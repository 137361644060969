import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import Input from "../../components/input";
import axios from "@/axios";
import Loader from "@shared/loader";
import GroupModal from "./create-group-modal.vue";
import Dropdown from "@shared/components/dropdown-base";
import { checkColumnConfig, prePopulatedDropdownItems } from "@shared/utils/functions";
import { checkPermission } from "@shared/utils/functions";

export default {
    name: "group-table",
    components: {
        SubHeader,
        Button,
        Input,
        GroupModal,
        Loader,
        Dropdown,
    },
    created() {
        // return this.$route.path;
    },
    title: "Groups",

    data() {
        return {
            columns: [
                {
                    label: "Group Name",
                    field: "title",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "name",
                    },
                },
                {
                    label: "No of users",
                    field: "user_count",
                    sortable:false,
                    filterOptions: {
                        enabled: false, // enable filter for this column
                    },
                },
                {
                    label: "Group description",
                    field: "description",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "description",
                    },
                },
            ],
            loading: false,
            showInputTypeModal: false,
            selectedField: null,
            groupname: "",
            clients: "",
            noOfUsers: "",
            groupdescription: "",
            active: true,
            items: [],
            tenant_id: null,
            searchTimeout: 500,
            selectedDropDownItems: null,
            searchParams: null,
            pageData:null,
            selectedOptions: {},
            perPage: 10
        };
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenant_id = this.$store.getters.getTenantId;
        this.items = await this.getGroups();
        this.columns = prePopulatedDropdownItems(this.columns, this.items);
    },
    methods: {
        checkPermission,
        async addGroup() {
            this.groupname = this.groupname.trim();
            this.groupdescription = this.groupdescription.trim();
            if(this.groupname.length === 0 || this.groupdescription.length===0)
            {
                return;
            }
            try {
                let { data } = await axios.post("group/tenant", {
                    title: this.groupname,
                    description: this.groupdescription,
                    active: this.active,
                    tenant_id: this.tenant_id,
                });
                this.$toast.success(data.message || "Group created");
                this.items.push({
                    title: this.groupname,
                    description: this.groupdescription,
                    user_count: 0,
                    id: data.group_id,
                });
                console.log(data, "asasas");
                this.$router.push({
                    name: "Group Setting",
                    params: {
                        id: data.group_id,
                    },
                });
                this.hanldeCloseModal();
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to delete group");
            }
        },
        async getGroups() {
            let items = [];
            try {
                this.loading = true;
                let { data } = await axios.get(`group/tenant/${this.tenant_id}`, { params: { ...this.searchParams } } );
                items = data;
            } catch (error) {
                console.log("error", error);
            }
            this.loading = false;
            return items;
        },
        hanldeCloseModal() {
            this.$modal.hide("group-modal");
        },
        handleShowModal() {
            this.selectedField = null;
            this.$modal.show("group-modal");
        },
        handleRowClick(data) {
            this.$router.push({
                name: "Group Setting",
                params: {
                    id: data.id,
                    ...data,
                },
            });
        },
        // Filters
        checkColumnConfig,
        async updateColumnFilters(column, value /* , callback */) {

            if(this.selectedOptions[column.label]){
                this.selectedOptions = {...this.selectedOptions,[column.label]:[...value]};
            }
            else
                this.selectedOptions = {...this.selectedOptions,[column.label]:[...value]}

            let selected = {
                key: column.filterOptions.query_key || column.field,
                value: column.filterOptions.multi ? value.map((el) => el.name) : [value?.name],
            };
            if (!selected.value?.length) {
                delete this.selectedDropDownItems[selected.key];
                return (this.items = await this.getGroups());
            }

            this.selectedDropDownItems = { ...this.selectedDropDownItems, [selected.key]: selected.value };
            let newLists = await this.filterListDropdownItem();
            this.items = newLists;
        },
        getSelectedOptions(column){
            return this.selectedOptions[column.label]
        },
        async filterListDropdownItem(params) {
            this.loading = true;
            let url = `group/tenant/groups`;
            let items = null;
            try {
                let body = {
                    tenant_id: this.tenant_id,
                    ...this.selectedDropDownItems,
                    ...params
                };
                let { data } = await axios.post(url, body);
                items = data.data;
                this.pageData = data?.page_data
            } catch (error) {
                console.log(error, "<<<<error while fetching users");
            }
            this.loading = false;
            return items;
        },
        searchFilter(column, event) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(column, event);
            }, delay);
        },
        async getSearchOptions(column, event) {
            let search = {
                key: column.filterOptions.query_key || column.field,
                value: event,
            };
            this.searchParams = { ...this.searchParams, [search.key]: search.value };
            let filteredDrowDowns = await this.getGroups();
            this.columns = prePopulatedDropdownItems(this.columns, filteredDrowDowns);
        },
        async onSortChange(data) {
            if (data && data.length && data[0].type !== "none") {
                data = data[0];
                let sortParams = {
                    sort: data.field + ":" + data.type,
                }
                await this.filterListDropdownItem(sortParams)

            } else {
                await this.filterListDropdownItem()

            }
        },
        async onPageChange() {
            await this.$nextTick();
            const scrollTarget = document.getElementById('vgt-table');
            if (scrollTarget) {
              scrollTarget.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
        },
        async clearFilters() {
            this.selectedOptions = {};
            this.pageData = null;
            this.selectedDropDownItems = null ;
            this.items = await this.getGroups();
        },
        onPerPageChange(e){
            this.perPage = e.currentPerPage
        },
    },
};
